import React from "react";
// import { graphql } from "gatsby";
import Root from "../Root";
import SEO from "../seo";
import Algorithms from "./Algorithms";

// export const query = graphql`
//   query($limit: Int!, $skip: Int!) {
//     allMongodbWheretomineAlgorithms(limit: $limit, skip: $skip) {
//       nodes {
//         name
//         numberOfSupportedCoins
//         slug
//       }
//     }
//   }
// `;

const AlgorithmsPage = ({ pageContext }) => {
  const { algorithms, currentPage, numberOfPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const prevPage = currentPage - 1 === 1 ? 0 : currentPage - 1;
  const nextPage = currentPage + 1;

  return (
    <Root>
      <SEO
        title="List Of All Cryptocurrency Algorithms"
        description="See what coins you can mine for the best Cryptocurrency Algorithms like Ethash, Equihash, Scrypt, SHA256 and many more."
      />
      <Algorithms
        algorithms={algorithms}
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      />
    </Root>
  );
};

export default AlgorithmsPage;
